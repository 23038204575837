define("ember-apollo-client/-private/deprecate-computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function deprecateComputed(property) {
    (false && !(false) && Ember.deprecate("Overwriting ember-apollo-client ".concat(property, " using computed property is deprecated. Please update to a regular function."), false, {
      id: 'ember-apollo-client.deprecate-mixins',
      until: '3.0.0'
    }));
  }

  var _default = deprecateComputed;
  _exports.default = _default;
});