define("ember-apollo-client/-private/mixins/base-query-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    apolloService: Ember.inject.service('apollo'),
    apollo: Ember.computed('apolloService', function () {
      return this.apolloService.createQueryManager();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(false) && Ember.deprecate("\nMixins in ember-apollo-client are deprecated, use queryManager macro instead.\n\nimport { queryManager } from 'ember-apollo-client';\n\nexport default Route.extend({\n  apollo: queryManager(),\n\n  // ...\n});", false, {
        id: 'ember-apollo-client.deprecate-mixins',
        until: '3.0.0'
      }));
    }
  });

  _exports.default = _default;
});