define("ember-cli-chart/components/ember-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'canvas',
    attributeBindings: ['width', 'height'],
    init: function init() {
      this._super.apply(this, arguments);

      this.plugins = this.plugins || [];
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var context = this.get('element');
      var data = this.get('data');
      var type = this.get('type');
      var options = this.get('options');
      var plugins = this.get('plugins');
      var chart = new Chart(context, {
        type: type,
        data: data,
        options: options,
        plugins: plugins
      });
      this.set('chart', chart);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('chart').destroy();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.updateChart();
    },
    updateChart: function updateChart() {
      var chart = this.get('chart');
      var data = this.get('data');
      var options = this.get('options');
      var animate = this.get('animate');

      if (chart) {
        chart.data = data;
        chart.options = options;

        if (animate) {
          chart.update();
        } else {
          chart.update(0);
        }
      }
    }
  });

  _exports.default = _default;
});