define("ember-apollo-client/-private/apollo/setup-hooks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setupHooks;
  var hooks = {
    willDestroyElement: function willDestroyElement() {
      this.unsubscribeAll(false);
    },
    beforeModel: function beforeModel() {
      this.markSubscriptionsStale();
    },
    resetController: function resetController(_, isExiting) {
      this.unsubscribeAll(!isExiting);
    },
    willDestroy: function willDestroy() {
      if (this.unsubscribeAll) {
        this.unsubscribeAll(false);
      }
    }
  };

  function installHook(queryManager, context, hookName) {
    var hook = hooks[hookName].bind(queryManager);
    var originalHook = context[hookName];

    context[hookName] = function () {
      if (typeof originalHook === 'function') {
        originalHook.call.apply(originalHook, [this].concat(Array.prototype.slice.call(arguments)));
      }

      hook.call.apply(hook, [queryManager].concat(Array.prototype.slice.call(arguments)));
    };
  }

  function setupHooks(queryManager, context) {
    if (context instanceof Ember.Component) {
      installHook(queryManager, context, 'willDestroyElement');
    } else if (context instanceof Ember.Route) {
      installHook(queryManager, context, 'beforeModel');
      installHook(queryManager, context, 'resetController');
      installHook(queryManager, context, 'willDestroy');
    } else {
      installHook(queryManager, context, 'willDestroy');
    }
  }
});