define("ember-changeset/utils/assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pureAssign;
  var getOwnPropertyDescriptors;

  if (Object.getOwnPropertyDescriptors !== undefined) {
    getOwnPropertyDescriptors = Object.getOwnPropertyDescriptors;
  } else {
    getOwnPropertyDescriptors = function getOwnPropertyDescriptors(obj) {
      var desc = {};
      Object.keys(obj).forEach(function (key) {
        desc[key] = Object.getOwnPropertyDescriptor(obj, key);
      });
      return desc;
    };
  } // keep getters and setters


  function pureAssign() {
    for (var _len = arguments.length, objects = new Array(_len), _key = 0; _key < _len; _key++) {
      objects[_key] = arguments[_key];
    }

    return objects.reduce(function (acc, obj) {
      return Object.defineProperties(acc, getOwnPropertyDescriptors(obj));
    }, {});
  }
});